
import axios from '../config/axios';

export const url = '/api/contactcollection'


export const createcontactcollection = ({
  token,
	namecontact,
	phonenumber,
	emailcontact,
	contactmessage,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token ) {

    axios.post(`${url}//create/basic/645160f5e474ae33c68ff71a`, {
		namecontact,
		phonenumber,
		emailcontact,
		contactmessage,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

