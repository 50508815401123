
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontactcollection } from '../../api/contactcollection.api'

import './principali0inb.scss';






const Pprincipali0inb = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  
  
  

  
  return (
    <div id="id66a4e328914da0d427ff3590" >

      <div id="if9wqu" className="">
      
      <div id="ihuh" className="">
      
      <div id="inlc" className="">
      
      <img
        className=""
        id="illj"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9h8z7ui.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="idpf" className="">
      
      <div id="i7cvw" className="">
      
        <a
          target=""
          className="link-menu "
          id="inlxjs"
          href="#ioyle"
        >
          
      <p className="" id="i2d1h">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      <span>Servicio de impresión 3D</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="i4d8t" className="">
      
        <a
          target=""
          className=""
          id="iv6wxw"
          href="#if7cvl"
        >
          
      <p className="" id="izded">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      <span>Materiales de Impresión</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="i9ute" className="">
      
        <a
          target=""
          className=""
          id="i34ziv"
          href="#itkxb"
        >
          
      <p className="" id="ihn67">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      <span>Ciberseguridad</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="ihh3i" className="">
      
        <a
          target=""
          className=""
          id="id21rj"
          href="#im8uk6"
        >
          
      <p className="" id="ih8nn">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      <span>Contacto</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="izrqjl" className="">
      
      </div>
      
      </div>
      
      <div id="iz8mc" className="">
      
      <div id="irkrg" className="">
      
      <div id="icjaj" className="">
      
      <div id="ibwb9" className="">
      
      <div id="i9a0g" className="">
      
      </div>
      
      </div>
      
      <p className="" id="irey9">
        <span>SERVICIO DE FABRICACIÓN DIGITAL Y CIBERSEGURIDAD</span>
      </p>
      
      </div>
      
      <div id="il8ni" className="">
      
      <p className="" id="ibn48">
        <span>Explora cómo podemos ayudarte a llevar tus proyectos al siguiente nivel.</span>
      <br className="" id="" />
      <span>¡Únete a la revolución de la fabricación digital con Black Maker</span>
      </p>
      
      </div>
      
      <div id="i7yrj" className="">
      
      </div>
      
      </div>
      
      <div id="i7rzq" className="">
      
      <img
        className=""
        id="iq0wx"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9h9vb73.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="itkxb" className="">
      
      <div id="igk42" className="">
      
      <div id="i2k9f" className="">
      
      </div>
      
      <div id="i8yjmg" className="">
      
      <div id="i1giw1" className="">
      
      <div id="ir7b67" className="">
      
      </div>
      
      </div>
      
      <p className="" id="iqqtir">
        <span>Ciberseguridad</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iy0kzs" className="">
      
      <div id="igd98s" className="">
      
      </div>
      
      <div id="i0r22k" className="gjs-row ">
      
      <div id="im9aj8" className="gjs-cell ">
      
      <div id="ieve6s" className="">
      
      <p className="titulo " id="ixpmmi">
        <span>Soluciones de Red</span>
      </p>
      
      </div>
      
      <div id="i0iq35" className="">
      
      <p className="descripcion " id="iq6w0u">
        <span>Ofrecemos servicios de red que integran seguridad y conectividad de alta calidad.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="inhjxp" className="gjs-cell ">
      
      <div id="in31bh" className="">
      
      <p className="titulo " id="irdrs2">
        <span>Implementación de Servidores</span>
      </p>
      
      </div>
      
      <div id="i8cii2" className="">
      
      <p className="descripcion " id="ibjm4s">
        <span>Una de las áreas tecnológicas que más ha evolucionado en los últimos años es el usoi de esquemas híbridos para implementción de los sistemas operativos de las empresas</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i79oau" className="gjs-cell ">
      
      <div id="iih9g6" className="">
      
      <p className="titulo " id="icpg6x">
        <span>Consultoría de Seguridad Informatica</span>
      </p>
      
      </div>
      
      <div id="ikyklj" className="">
      
      <p className="descripcion " id="irckav">
        <span>Haciendo uso de herramientas especializadas por marcas líderes a nivel global, prodemos brindarles un enfoque profesional para el estudio de la infraestructura de Ciberseguridad con la que cuenta su empresa</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="izacmz" className="gjs-cell ">
      
      <div id="i0ny5i" className="">
      
      <p className="titulo " id="iz8kkg">
        <span>Seguridad de Aplicaciones Web</span>
      </p>
      
      </div>
      
      <div id="iazuqc" className="">
      
      <p className="descripcion " id="i6y3mj">
        <span>Protegemos sus aplicaciones web contra una aplia gama de amenazas, incluyendo ataques de dia cero, inyecciones SQL, cross-site scripting (XSS). Este servicio garantiza la integridad y seguridad de sus aplicaciones en línea.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="is2zfi" className="">
      
      </div>
      
      </div>
      
      <div id="iqrg2c" className="">
      
      <div id="iybi2m" className="">
      
      </div>
      
      <div id="inrd62" className="">
      
      <p className="" id="iytiv9">
        <span>MARCAS DESTACADAS</span>
      </p>
      
      <p className="" id="iz6kkb">
        <span>Nos enorgullecemos de colaborar con las marcas más reconocidas y confiables en el mundo de la fabricación digital. Nuestra alianza con estos líderes del mercado nos permite ofrecerte soluciones de alta calidad, innovadoras y eficientes para tus necesidades de fabricación.</span>
      </p>
      
      </div>
      
      <div id="i93yy4" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ioyle" className="">
      
      <div id="iee7l" className="">
      
      <div id="ir5tk" className="">
      
      <div id="i0t65" className="">
      
      <div id="imw8i" className="">
      
      </div>
      
      </div>
      
      <p className="" id="ii08k">
        <span>SERVICIO DE IMPRESIÓN 3D</span>
      </p>
      
      </div>
      
      <div id="iokjz" className="">
      
      <p className="" id="ico7l">
        <span>Somos expertos en la creación e impresión 3D</span>
      </p>
      
      </div>
      
      <div id="i07jy" className="">
      
      </div>
      
      </div>
      
      <div id="igokd" className="">
      
      <div id="iamm5" className="">
      
      <img
        className=""
        id="i48ku"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hb77jm.png"
        alt="undefined"
      />
      
      <p className="" id="i87jx">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      </div>
      
      <div id="ivm5v" className="">
      
      <img
        className=""
        id="i7mg5"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hbepe5.png"
        alt="undefined"
      />
      
      <p className="" id="ihys9">
        
      <p className="MsoNormal " id="">
        <span>SIMULACIÓN EN 3D</span>
      </p>
      
      </p>
      
      </div>
      
      <div id="ibyyv" className="">
      
      <img
        className=""
        id="it7ji"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hbevoo.png"
        alt="undefined"
      />
      
      <p className="" id="i3e3f">
        <span>FABRICACIÓN  OPTIMIZADA</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="if7cvl" className="">
      
      <div id="i7v3nj" className="">
      
      <div id="iri6nk" className="">
      
      </div>
      
      <div id="is9fbi" className="">
      
      <div id="ibsh6e" className="">
      
      <div id="i4e2g8" className="">
      
      </div>
      
      </div>
      
      <p className="" id="ilpn6s">
        <span>MATERIALES DE IMPRESIÓN </span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i13hzg" className="">
      
      <div id="ixu4ka" className="">
      
      </div>
      
      <div id="i95cyf" className="gjs-row ">
      
      <div id="itnnmf" className="gjs-cell ">
      
      <div id="ifpf28" className="">
      
      <p className="" id="im9bd5">
        <span>Nos especializamos en ofrecer soluciones de fabricación digital utilizando una variedad de materiales de alta calidad que se adaptan a tus necesidades específicas.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="il84yx" className="gjs-cell ">
      
      <div id="i5v0ag" className="">
      
      <img
        className=""
        id="iwil1l"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hht0yz.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iwsjnr" className="">
      
      <p className="" id="i3r2qq">
        <span>PLA FILAMENTO</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i2815g" className="gjs-cell ">
      
      <div id="iz8cko" className="">
      
      <img
        className=""
        id="ij0n11"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hhzkdf.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ivn2qp" className="">
      
      <p className="" id="ijbl6m">
        <span>MADERA</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iydl7f" className="gjs-cell ">
      
      <div id="i1aejp" className="">
      
      <img
        className=""
        id="ihijoh"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hi0r1i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i5p3xm" className="">
      
      <p className="" id="iql052">
        <span>MDR</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i68ult" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="im8uk6" className="">
      
      <div id="ile7op" className="">
      
      <div id="ixmaj4" className="">
      
      </div>
      
      <div id="i7vpvm" className="">
      
      <div id="i4569h" className="">
      
      <div id="i98nvj" className="">
      
      </div>
      
      </div>
      
      <p className="" id="iwrrfj">
        <span>CONTACTO</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iz6bji" className="">
      
      <div id="imey7v" className="">
      
      <div id="iaovx1" className="">
      
      <p className="" id="iqkjvg">
        <span>Valoramos cada proyecto y nos dedicamos a ofrecerte la mejor experiencia en fabricación digital. ¿Tienes una idea, una pregunta o necesitas más información sobre nuestros servicios? No dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte en cada paso del camino.</span>
      </p>
      
      <p className="" id="i8aw0j">
        
      <p className="MsoNormal " id="">
        <span>Dirección</span>
    <div id="" className="">
    
    </div>
    
      </p>
      
      </p>
      
      <p className="" id="is6mpj">
        <span>Oficina Beetik, Novacentro, Tegucigalpa, Honduras</span>
      </p>
      
      <p className="" id="il35yc">
        <span>Correo</span>
      </p>
      
      <p className="" id="ixvud7">
        <span>company@black-maker.com</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="id6zqn" className="">
      
      <div id="is5k2g" className="">
      
        <div className="" id="iuu374">
          
      <form
        id="ittqxg"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontactcollection({
						namecontact: e.target.namecontact.value,
						phonenumber: e.target.phonenumber.value,
						emailcontact: e.target.emailcontact.value,
						contactmessage: e.target.contactmessage.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="igy239" className="">
      
      <label id="iet9pr" className="">
      <span>Nombre</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="namecontact"
        type="text"
        placeholder=""
        className=""
        id="in7yge"
      />
      
      <div id="iaeliw" className="">
      
      </div>
      
      </div>
      
      <div id="iss7og" className="">
      
      <label id="idvkmj" className="">
      <span>Teléfono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="phonenumber"
        type="text"
        placeholder=""
        className=""
        id="i19iif"
      />
      
      <div id="iuknkx" className="">
      
      </div>
      
      </div>
      
      <div id="is643o" className="">
      
      <label id="ioozel" className="">
      <span>Correo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="emailcontact"
        type="text"
        placeholder=""
        className=""
        id="ity6ca"
      />
      
      <div id="ie2pxa" className="">
      
      </div>
      
      </div>
      
      <div id="ihvc7j" className="">
      
      <label id="iq375g" className="">
      <span>Mensaje</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="contactmessage"
        type="text"
        placeholder=""
        className=""
        id="ik91rd"
      />
      
      <div id="ilgeip" className="">
      
      </div>
      
      </div>
      
      <button
        type="submit"
        name=""
        id="i58n9p"
        className=""
        
      >
      <span>ENVIAR MENSAJE
              </span>
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i77go" className="">
      
      <img
        className=""
        id="i2x09m"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hjl0pb.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="imxd3m"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hjs930.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="is3wb5"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9hjspxc.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ilkxzh" className="">
      
      <p className="" id="ikgcxg">
        <span>© 2024 blackmaker,  All Rights Reserved</span>
      </p>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pprincipali0inb);
  