
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './portfolio.scss';






const Pportfolio = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id6353093593bf11cc1baddfce" >

      <div id="if9wqu" className="">
      
      <div id="ihuh" className="">
      
      <div id="inlc" className="">
      
      <img
        className=""
        id="illj"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9h8z7ui.png"
        alt="undefined"
      />
      
      <div id="ici8dx" className="">
      
      </div>
      
      </div>
      
      <div id="idpf" className="">
      
      <div id="i7cvw" className="">
      
      <p className="" id="i2d1h">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        <span>Fabricación Digital</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="i9ute" className="">
      
      <p className="" id="ihn67">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        <span>Herramientas</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="i4d8t" className="">
      
      <p className="" id="izded">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        <span>Materiales de Impresión</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="ihh3i" className="">
      
      <p className="" id="ih8nn">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        <span>Contacto</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="izrqjl" className="">
      
      <p className="" id="id8lda">
        
      <i className="" id="">
        <span>HAMBURGER</span>
      </i>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="itkxb" className="">
      
      <div id="igk42" className="">
      
      <div id="i2k9f" className="">
      
      </div>
      
      <div id="i8yjmg" className="">
      
      <div id="i1giw1" className="">
      
      <div id="ir7b67" className="">
      
      </div>
      
      </div>
      
      <p className="" id="iqqtir">
        <span>Portafolio</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iqrg2c" className="">
      
      <div id="iybi2m" className="">
      
      </div>
      
      <div id="inrd62" className="">
      
      <p className="" id="iytiv9">
        <span>Tipo de Material</span>
      </p>
      
      <p className="" id="iz6kkb">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat vestibulum, id imperdiet urna molestie odio. Volutpat turpis condimentum nec neque vitae, netus ut malesuada.</span>
      </p>
      
      </div>
      
      <div id="i93yy4" className="">
      
      </div>
      
      </div>
      
      <div id="i79nhx" className="">
      
      <div id="ilawjs" className="">
      
      </div>
      
      <div id="iwc9uq" className="gjs-row ">
      
      <div id="i225kn" className="gjs-cell ">
      
      <div id="i86bjo" className="">
      
      <p className="" id="iubvki">
        <span>Modeling</span>
      </p>
      
      <p className="" id="iqk98g">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="i2qbth">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i4mbhx" className="gjs-cell ">
      
      <div id="ij78fe" className="">
      
      </div>
      
      <div id="iuq5rd" className="">
      
      <p className="" id="i4hwm3">
        <span>Modeling</span>
      </p>
      
      <p className="" id="itn9tz">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="iym76r">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      <p className="" id="ivli0j">
        
    <div id="" className="">
    <span>14” al x 14” ancho</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="itbygo" className="gjs-cell ">
      
      <div id="i8cf3k" className="">
      
      <p className="" id="il598c">
        <span>Modeling</span>
      </p>
      
      <p className="" id="i2cxdh">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="i2oht1">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      <p className="" id="ibx0m7">
        
    <div id="" className="">
    <span>14” al x 14” ancho</span>
    </div>
    
      </p>
      
      </div>
      
      <div id="i4f58n" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="inabor" className="">
      
      </div>
      
      </div>
      
      <div id="ixjb7g" className="">
      
      <div id="iqz0wy" className="">
      
      </div>
      
      <div id="irhufq" className="">
      
      <p className="" id="ic4tqi">
        <span>Tipo de Material</span>
      </p>
      
      <p className="" id="i4km5p">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat vestibulum, id imperdiet urna molestie odio. Volutpat turpis condimentum nec neque vitae, netus ut malesuada.</span>
      </p>
      
      </div>
      
      <div id="in1i1u" className="">
      
      </div>
      
      </div>
      
      <div id="iy0kzs" className="">
      
      <div id="igd98s" className="">
      
      </div>
      
      <div id="i0r22k" className="gjs-row ">
      
      <div id="im9aj8" className="gjs-cell ">
      
      <div id="i5cbcs" className="">
      
      <p className="" id="izm6hd">
        <span>Modeling</span>
      </p>
      
      <p className="" id="ism2ml">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="i60fbk">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="ib32hv" className="gjs-cell ">
      
      <div id="ie3crw" className="">
      
      </div>
      
      <div id="iicpbi" className="">
      
      <p className="" id="igyj4a">
        <span>Modeling</span>
      </p>
      
      <p className="" id="ifcma2">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="iteo37">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      <p className="" id="i46q5l">
        
    <div id="" className="">
    <span>14” al x 14” ancho</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i1npuo" className="gjs-cell ">
      
      <div id="itq5v7" className="">
      
      <p className="" id="ivg7vp">
        <span>Modeling</span>
      </p>
      
      <p className="" id="io1i0m">
        <span>CONCEPTUALIZACIÓN</span>
      </p>
      
      <p className="" id="ij74ak">
        
    <div id="" className="">
    <span>Impresión sobre plástico</span>
    </div>
    
      </p>
      
      <p className="" id="ioor6e">
        
    <div id="" className="">
    <span>14” al x 14” ancho</span>
    </div>
    
      </p>
      
      </div>
      
      <div id="i2j4rg" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="is2zfi" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ilkxzh" className="">
      
      <p className="" id="ikgcxg">
        <span>© 2022 blackmaker,  All Rights Reserved</span>
      </p>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pportfolio);
  